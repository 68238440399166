import React from "react";
import styled, { useTheme, createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Noto Music', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    circle at 50% -20%,
    ${({ theme }) => theme.colors.highlight} 0%,
    ${({ theme }) => theme.colors.accent} 35%,
    #2C1B3D 65%,
    ${({ theme }) => theme.colors.background} 80%
  );
`;

const TitleContainer = styled.div`
  display: grid;
  position: relative;
  pointer-events: none;
`;

const LogoImage = styled.img`
  max-width: 80%;
  max-height: 60vh;
  margin: 0 auto;
  display: block;
  pointer-events: none;
`;

const TitleBase = styled.h1`
  font-family: 'Noto Music', sans-serif;
  font-size: 8rem;
  color: white;
  display: flex;
  align-items: center;
  grid-area: 1/1/-1/-1;
  margin: 0;
    pointer-events: none;
`;

const TitleTop = styled(TitleBase)`
  clip-path: polygon(0% 0%, 100% 0%, 100% 48%, 0% 58%);
    pointer-events: none;
`;

const ReverseMixIn = styled.span`
  display: inline-block;
  transform: scaleX(-1);
  position: relative;
  z-index: 1;
  pointer-events: none;
`;

const TitleBottom = styled(TitleBase)`
  clip-path: polygon(0% 60%, 100% 50%, 100% 100%, 0% 100%);
  color: transparent;
  background: linear-gradient(177deg, rgba(0,0,0,0.7) 53%, white 65%);
  -webkit-background-clip: text;
  background-clip: text;
  transform: translateX(-0.02em);
  pointer-events: none;
  
  ${ReverseMixIn} {
    background: inherit;
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

const ContentSection = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 4rem 2rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 0 1rem; // Smaller padding on mobile
  margin: 0 auto;
  
  @media (min-width: 480px) {
    padding: 0 1.5rem; // Larger padding on wider screens
    max-width: 430px;
  }
`;

const PixelWrapper = styled.div`
  position: relative;
  
  pixel-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.8;
  }
  
  > * {
    position: relative;
    z-index: 1;
  }
`;

// Typography Components
const Title = styled.h2`
  font-family: 'Noto Music', sans-serif;
  font-size: ${props => props.size || '3rem'};
  color: white;
  margin-bottom: 1.5rem;
`;

const Subtitle = styled.h3`
  font-family: 'Noto Music', sans-serif;
  font-size: ${props => props.size || '1.5rem'};
  color: white;
  margin-bottom: 1rem;
`;

const BodyText = styled.p`
  font-family: 'Noto Music', sans-serif;
  font-size: ${props => props.size || '1.2rem'};
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.65;
  padding-bottom: 1.2rem;
`;

const StyledList = styled.ul`
  padding-left: 3rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  font-family: 'Noto Music', sans-serif;
  line-height: 1.65;
  padding-bottom: 1.2rem;
`;

const SubEntryList = styled(StyledList)`
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  
  li {
    font-size: 1rem;
    opacity: 0.85;
  }
`;

const AccentText = styled.span`
  color: ${({ theme }) => theme.colors.accent};
`;

const LinkText = styled.a`
  text-decoration: underline;
  font-weight: bold;
  color: inherit;
  transition: color 0.2s;

  &:visited, &:hover, &:active, &:link {
    color: inherit;
  }
`;

const Spacer = styled.div`
  height: ${props => props.size || '1.5rem'};
  width: 100%;
`;

const StyledButton = styled.a`
  display: inline-block;
  padding: 0.8rem 1.8rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  color: white;
  font-family: 'Noto Music', sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25), inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, 
      transparent, 
      rgba(${({ theme }) => theme.colors.accent.replace(/[^\d,]/g, '')}, 0.2), 
      transparent
    );
    transform: translateX(-100%);
    transition: transform 0.8s ease;
  }
  
  &:hover {
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
    transform: translateY(-2px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
    
    &::before {
      transform: translateX(100%);
    }
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }
`;

function AppContent() {
  const theme = useTheme();

  return (
    <>
      <GlobalStyle />
      <Container>
        <TitleContainer>
          <LogoImage src="/logo_trans.png" alt="ED3N Logo" />
        </TitleContainer>
      </Container>
      <ContentSection>
        <ContentContainer>
          <PixelWrapper>
            <pixel-canvas
              data-gap="10"
              data-speed="25"
              data-colors="#e0f2fe, #7dd3fc, #0ea5e9"
            />
            <Title>
              <AccentText>hack // social</AccentText>
            </Title>
            <StyledButton href="https://drive.google.com/drive/folders/1LcROn7a55hKHLgNeKyyb8mPNc__y4Ie1?usp=sharing" target="_blank" rel="noopener noreferrer">
              Photos
            </StyledButton>
          </PixelWrapper>
          <Spacer />
          <Spacer />
          <Spacer />
          <PixelWrapper>
            <pixel-canvas
              data-gap="10"
              data-speed="25"
              data-colors=" #0D2B16, #0FFF95, #00FF41"
            />
            <Title>ab initio</Title>
            <BodyText>
              born in the digital age, we're an underground team building deep tech.
            </BodyText>
          </PixelWrapper>
        </ContentContainer>
      </ContentSection>
    </>
  );
}

export default AppContent;
